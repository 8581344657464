import moment from 'moment'

export const getDefaultLocale = () => {
  if (typeof window === 'undefined') return null

  const { userLanguage, language } = window.navigator
  const locale = (userLanguage || language || 'en').substr(0, 2)
  moment.locale(locale)
  return locale
}

export const range = (start, end) => {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}
export const sameDay = (date, values, format) => {
  if(!date.isValid()) return false;

  if(values.min && values.max)
    return  date.weekday() === moment(values.min, format).locale('en').weekday() && date.weekday() === moment(values.max, format).locale('en').weekday() && date.isSame(moment(values.min, format).locale('en'));
  if(values.min)
    return  date.weekday() === moment(values.min, format).locale('en').weekday();
  if(values.max)
    return  date.weekday() === moment(values.max, format).locale('en').weekday();
}
